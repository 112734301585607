import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/Layout";
import { Home } from "./components/pages/Home";
import { CanvasPage } from "./components/pages/Canvas";
import { Project } from "./components/pages/Project";
import { UnderConstructionPage } from "./components/pages/UnderConstruction";
import ScrollToTop from "./hooks/ScrollToTop";
import { ProjectDetails } from "./components/pages/ProjectDetails";
import { About } from "./components/pages/About";
import { Contact } from "./components/pages/Contact";
import useExternalScripts from "../src/hooks/useExternalScripts";
import { useEffect, useState } from "react";
import ScrollToAnchor from "./components/common/ScrollToAnchor";
const App = () => {
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [isDesktop, setIsDesktop] = useState(false);
  const [hasMouseMoved, setHasMouseMoved] = useState(false);
  const handleMouseMove = (e: any) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
    if(hasMouseMoved === false){
      setHasMouseMoved(true);
    }
  };

  useExternalScripts({ url: "/./index.js" });

  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);

  return (
    <>
      <style>
        {`    
            .cursorNotVisible {
              display: none;
            }
.cursor {
  z-index: 1000;
    pointer-events: none;
    position: fixed;
    padding: 0.3rem;
    background-color: #fff;
    border-radius: 50%;
    mix-blend-mode: difference;
    transition: transform 0.3s ease;
  }
            `}
      </style>
      <div className="App cursor-none " id="root" onMouseMove={handleMouseMove}>
        <CanvasPage />
        <BrowserRouter>
          <ScrollToAnchor />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="projects" element={<Project  />} />
              <Route path="about" element={<About />} />
              <Route path="contact" element={<Contact />} />
              <Route
                path="under_construction"
                element={<UnderConstructionPage />}
              />
              <Route
                path="projects/:projectName"
                element={<ProjectDetails />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
      <div
        className={`z-50 ${!isDesktop ? "cursorNotVisible" : "cursor"}`}
        style={{
          left: cursorPos.x + "px",
          top: cursorPos.y + "px",
          transform: `${(hasMouseMoved===false?"translate(-200%, -200%)":"translate(0%, 0%)")} scale(3.5)`,
        }}
      ></div>
    </>
  );
};

export default App;
