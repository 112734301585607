import { useEffect, useState } from "react";
import { AcademicContent, PhotographyContent, ProfessionalContent, ProjectLandingTabs } from "../../Constant";
import { PictureGallery } from "../common/PictureGallery";
import { Option_Media_Link_Tabs } from "../../types";
import { useLocation } from "react-router-dom";

export const Project = (props:{
    returnedTabIndex?:string
}) => {
  const location = useLocation()
    const [activeTab, setactiveTab] = useState<number>(0);
useEffect( () => {
    if(!location?.state?.activeTab){
        setactiveTab(0)
    }else{
        setactiveTab(location?.state?.activeTab)
    }

} ,[location?.state?.activeTab])
    return (
        <>
       
            <div className="pageFadeAnimation text-primary-cream">
                <div className="font-semibold text-heading_lg lg:text-title_sm h-[90vh] select-none">
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2/3 lg:w-full text-center break-words text-heading_lg sm:text-heading_xl lg:text-title_sm text-wrap ">
                        FEATURED WORK
                    </div>
                </div>
                <div className="flex justify-center" id="projects"> 
                    <div className="flex justify-center items-center gap-10 md:gap-16 lg:gap-20 border-b text-md lg:text-xl select-none">
                        {
                            ProjectLandingTabs.map( (tab: Option_Media_Link_Tabs, index: number) => (
                                <div key={index} className={`pb-1 ${activeTab === index ? 'text-primary-green border-b-[3px] border-primary-green' : 'text-grey_neutral-light_grey'}`} onClick={() => setactiveTab(index)}>{tab.Label}</div>
                            ))
                        }
                    </div>
                </div>
                {
                    activeTab === 0 ?
                    <PictureGallery activeTab={0}  ImageAndTextArray={ProfessionalContent} />
                    :
                    activeTab === 1 ?
                    <PictureGallery activeTab={1} ImageAndTextArray={PhotographyContent} />
                    :
                    activeTab === 2 &&
                    <PictureGallery activeTab={2} ImageAndTextArray={AcademicContent} />
                }
            </div>
        </>
    );
};