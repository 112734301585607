import { useEffect, useState } from "react";
import { ImageFormat, PictureGalleryFormat } from "../../types";
import { useNavigate } from "react-router-dom";
import { motion, Variants } from "framer-motion";

export const PictureGallery = (props: PictureGalleryFormat) => {
  const navigate = useNavigate();
  const { ImageAndTextArray } = props;
  const [activeLabel, setactiveLabel] = useState<number>(1);
  const [animateCard, setAnimateCard] = useState<boolean>(false);

  useEffect(() => {
    setactiveLabel(1);
  }, [ImageAndTextArray]);

  useEffect(() => {
    setAnimateCard(true);
    const timeoutId = setTimeout(() => {
      setAnimateCard(false);
    }, 1000); 
    return () => clearTimeout(timeoutId);
  }, [props.activeTab]);

  const textVariants: Variants = {
    offscreen: {
      opacity:0
    },
    onscreen: {
      opacity:1 ,
      transition: {
        type: "spring",
        bounce: 0.0,
        duration: 1
      },
    },
  };
  return (
    <>
      <style>
        {`
            
@-webkit-keyframes tracking-in-expand-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-700px) translateY(250px);
              transform: translateZ(-700px) translateY(250px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand-fwd-bottom {
    0% {
      -webkit-transform: translateZ(-700px) translateY(250px);
              transform: translateZ(-700px) translateY(250px);
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  .tracking-in-expand-fwd-bottom {
      -webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
              animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  }
      
  @keyframes fadeInText{
    0%{
      opacity: 0;
    },

    100%{
      opacity: 1;
    }
  }   
           
  .fadeInTextAnimation{
    -webkit-animation: fadeInText ease 1s;
    animation: fadeInText ease 1s;
   
  }
           `}
      </style>
      <div className="lg:mt-3 lg:mb-3">
        <div className="p-7 py-16 lg:flex lg:items-center lg:justify-evenly lg:pt-16 lg:px-28 lg:pb-32 text-primary-cream w-full">
          <div className="lg:flex lg:justify-center lg:flex-col lg:w-1/2">
            {ImageAndTextArray &&
              ImageAndTextArray?.map((content: ImageFormat, index: number) => (
                <>
                  <div
                    className="border-b-[1px] lg:border-b-0 lg:py-0 py-6"
                    key={index}
                  >
                    <div
                      className={`  sm:flex sm:gap-5 sm:justify-between sm:items-center group overflow-hidden`}
                    >
                      <div
                        className={`overflow-hidden relative text-primary-cream lg:my-3  text-heading_sm sm:text-heading_md lg:text-heading_lg ${
                          activeLabel === content.Id
                            ? "group-hover:lg:text-primary-cream"
                            : "lg:text-grey_neutral-light_grey"
                        } ${content.Label === "Tiffany & Co." ? "pr-2.5" : ""}`}
                        onMouseEnter={() => {
                          setactiveLabel(content.Id);
                        }}
                        onClick={() =>
                          navigate(
                            `/projects/${content.Label.replaceAll(
                              " ",
                              "_"
                            ).toLowerCase()}`,
                            { state: { activeTab: props.activeTab } }
                          )
                        }
                      >
                        <motion.div
                          className={`card-container ${
                            animateCard ? "fadeInTextAnimation" : ""
                          }`}
                          initial="offscreen"
                          whileInView="onscreen"
                          viewport={{ once: true, amount: 0.2 }}
                        >
                          <div className="splash" />
                          <motion.div
                            className={`card h-full w-full ${
                              animateCard ? "fadeInTextAnimation" : ""
                            }`}
                            variants={textVariants}
                          >
                            {content.Label}
                            <sup
                              className={`top-[-18px] ml-3 lg:ml-1 text-sm sm:text-lg text-primary-green ${
                                activeLabel === content.Id
                                  ? "group-hover:lg:text-primary-green"
                                  : "lg:text-grey_neutral-light_grey"
                              }`}
                            >
                              (0{content.Id})
                            </sup>
                          </motion.div>
                        </motion.div>
                      </div>
                      <div className={`img imagesDivMobile ${ animateCard ? "fadeInTextAnimation" : "" }  mt-3 sm:mt-0 sm:h-full sm:w-[200px] lg:hidden`}>
                        {content.Image?.split(".")[2] === "mp4" ? (
                          <video
                            typeof="mp4"
                            muted
                            autoPlay
                            loop
                            playsInline
                            width="100%"
                            height="100%"
                            onClick={() =>
                              navigate(
                                `/projects/${content.Label.replaceAll(
                                  " ",
                                  "_"
                                ).toLowerCase()}`
                              )
                            }
                          >
                            <source type="video/mp4" src={content?.Image} />
                          </video>
                        ) : (
                          <img
                            className="w-full h-full align-middle"
                            src={content.Image}
                            alt="No Image Found"
                            onClick={() =>
                              navigate(
                                `/projects/${content.Label.replaceAll(
                                  " ",
                                  "_"
                                ).toLowerCase()}`
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                    {/* <hr className="my-6 lg:hidden" /> */}
                  </div>
                </>
              ))}
          </div>
          <div className={`imagesDiv hidden ${ animateCard ? "fadeInTextAnimation" : "" } lg:flex lg:items-center lg:justify-center lg:w-[700px] lg:h-[455px]`}>
            {ImageAndTextArray &&
              ImageAndTextArray?.map((item: ImageFormat, index: number) => {
                if (item.Id === activeLabel) {
                  return (
                    <>
                      {item.Image?.split(".")[2] === "mp4" ? (
                        <video
                          key={index}
                          typeof="mp4"
                          muted
                          autoPlay
                          playsInline
                          loop
                          className=""
                          width="100%"
                          height="100%"
                          onClick={() =>
                            navigate(
                              `/projects/${item.Label.replaceAll(
                                " ",
                                "_"
                              ).toLowerCase()}`
                            )
                          }
                        >
                          <source type="video/mp4" src={item?.Image} />
                        </video>
                      ) : (
                        <img
                          key={index}
                          className="w-full h-full align-middle "
                          src={item.Image}
                          alt="No Image Found"
                          onClick={() =>
                            navigate(
                              `/projects/${item.Label.replaceAll(
                                " ",
                                "_"
                              ).toLowerCase()}`
                            )
                          }
                        />
                      )}
                    </>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};
