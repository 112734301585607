import { useRef, useState } from "react";
import leftArrowButton from "../../assets/CarousalArrowSquareLeft.png";
import rightArrowButton from "../../assets/CarousalArrowSquareRight.png";
import { ProfessionalProps, SubImage } from "../../types";
import { useEffect } from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import EmblaCarousel from "./emblaCarousel";
import { motion, Variants } from "framer-motion";

export const ProfessionalDetail = (props: ProfessionalProps) => {
  const { Details } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const handleSlide = (index: number) => setCurrentIndex(index);

  const OPTIONS: EmblaOptionsType = {
    loop: true,
    skipSnaps: false,
    containScroll: "keepSnaps",
  };
  const SLIDE_COUNT = Details.CarousalImages?.length;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  const cardVariants: Variants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.0,
        duration: 3,
      },
    },
  };

  const textVariants: Variants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.0,
        duration: 3,
      },
      opacity: 1,
    },
  };

  return (
    <>
      {" "}
      <style>
        {`
    .image-gallery-icon:hover{
      color :unset;
    }
    .image-gallery-thumbnail:hover{
border:unset;
    }
    .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus{
      border: unset;
    }
@-webkit-keyframes tracking-in-expand-fwd-bottom {
0% {
-webkit-transform: translateZ(-700px) translateY(250px);
      transform: translateZ(-700px) translateY(250px);
opacity: 0;
}
40% {
opacity: 0.6;
}
100% {
-webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
opacity: 1;
}
}
@keyframes tracking-in-expand-fwd-bottom {
0% {
-webkit-transform: translateZ(-700px) translateY(250px);
      transform: translateZ(-700px) translateY(250px);
opacity: 0;
}
40% {
opacity: 0.6;
}
100% {
-webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
opacity: 1;
}
}
.tracking-in-expand-fwd-bottom {
-webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
    `}
      </style>
      <div className="pageFadeAnimation md:relative md:-top-[75px] text-primary-cream">
        {Details && Details?.Tag === "tumo" ? (
          <>
            <video
              autoPlay
              loop
              typeof="mp4"
              controls
              width="100%"
              height="100%"
              muted
            >
              <source type="video/mp4" src={Details?.Main?.Video} />
            </video>
          </>
        ) : (
          <>
            <img
              className="w-full h-full align-middle"
              src={Details.Main?.Image}
              alt="Image Not Found"
            />
          </>
        )}

        <div
          className={`pt-6 pl-6 pr-6 items-center gap-12 md:gap-6 flex flex-col ${
            Details?.CarousalImages?.length! > 1 && "pb-6"
          }`}
        >
          <div className="w-full flex flex-col md:flex-row md:justify-between items-start lg:px-12 lg:py-10">
            <div className="md:w-1/3">
              <div className="mb-6 text-heading_md md:text-heading_lg lg:text-heading_xl overflow-hidden">
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <div className="splash" />
                  <motion.div
                    className="card h-full w-full"
                    variants={textVariants}
                  >
                    {Details.Label}{" "}
                  </motion.div>
                </motion.div>
              </div>
              {Details.Roles &&
                Details.Roles.map((role: string, index: number) => (
                  <div
                    key={index}
                    className="mb-6 text-sm xl:text-md text-grey_neutral-light_grey overflow-hidden"
                  >
                    <motion.div
                      className="card-container"
                      initial="offscreen"
                      whileInView="onscreen"
                      viewport={{ once: true, amount: 0.2 }}
                    >
                      <div className="splash" />
                      <motion.div
                        className="card h-full w-full"
                        variants={textVariants}
                      >
                        {role}{" "}
                      </motion.div>
                    </motion.div>
                  </div>
                ))}
            </div>
            <div className="md:w-2/3">
              <div className="select-none description pb-6 pr-3 md:pr-0 text-md lg:text-xl align-top whitespace-pre-line overflow-hidden">
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.2 }}
                >
                  <div className="splash" />
                  <motion.div
                    className="card h-full w-full"
                    variants={textVariants}
                  >
                    {Details?.Description}
                  </motion.div>
                </motion.div>
              </div>
              <hr className="" />
              <div className="w-3/4 md:w-1/2">
                <div
                  className={`logosDiv mt-6 flex ${
                    Details?.Logos?.length < 3
                      ? "gap-10 md:gap-20"
                      : "gap-3 md:gap-20"
                  } gap-10 md:gap-20 items-center w-full`}
                >
                  {Details.Logos &&
                    Details.Logos.map((image: string, index: number) => (
                      <div
                        key={index}
                        className={` ${Details?.Logos?.length<=2 ? "w-28 sm:w-32":"w-28 sm:w-32 lg:w-full"} overflow-hidden`}
                      >
                        <motion.div
                          className="card-container"
                          initial="offscreen"
                          whileInView="onscreen"
                          viewport={{ once: true, amount: 0.2 }}
                        >
                          <div className="splash" />
                          <motion.div
                            className="card h-full w-full"
                            variants={textVariants}
                          >
                            <img
                              className="w-full h-full object-fill aspect-[3/2] align-middle"
                              src={image}
                              alt="No Image Found"
                            />
                          </motion.div>
                        </motion.div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex flex-col gap-4 sm:gap-6 w-full h-full">
              {Details.Images &&
                Details?.Images.map((imgObject: SubImage, index: number) => (
                  <>
                    <div
                      key={index}
                      className="w-full h-full gap-2 sm:gap-4 lg:gap-5 flex"
                    >
                      {imgObject.SubImage.map(
                        (src: string, innerIndex: number) => (
                          <motion.div
                            key={innerIndex}
                            className="card-container w-full h-full"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.2 }}
                          >
                            <div className="splash" />
                            <motion.div
                              className="card h-full w-full"
                              variants={cardVariants}
                            >
                              <img
                                alt="No Image Found"
                                src={src}
                                className="w-full h-full align-middle"
                              />{" "}
                            </motion.div>
                          </motion.div>
                        )
                      )}
                    </div>
                  </>
                ))}
            </div>
          </div>
        </div>
        {/* embla carousel here */}

        {Details.CarousalImages && Details?.CarousalImages?.length > 0 && (
          <div className="carouselDiv w-full h-full flex justify-center bg-primary-cream select-none px-4 lg:px-0 md:py-20 py-8 sm:py-3 lg:mt-10">
            <EmblaCarousel
              slides={SLIDES}
              options={OPTIONS}
              images={Details?.CarousalImages}
            />
          </div>
        )}
      </div>
    </>
  );
};
