import { PhotoAndAcademicProps } from "../../types";
import EmblaThumbnailCarousel from "./emblaThumbnailCarousel";
import ImageGallery from "react-image-gallery";
import { EmblaOptionsType } from "embla-carousel";
import { useEffect, useRef, useState } from "react";
import rightArrowButton from "../../assets/CarousalArrowSquareRight.png";
import leftArrowButton from "../../assets/CarousalArrowSquareLeft.png";

export const PhotoAndAcademicDetail = (props: PhotoAndAcademicProps) => {
  const [currentCaption, setCurrentCaption] = useState<string>("");
  const [animationClass, setAnimationClass] = useState<string>("");
  const [images, setImages] = useState<any>([]);
  const [showThumbnailsOnFullScreen, setShowThumbnailsOnFullscreen] =
    useState(true);
  const { Details } = props;
  useEffect(() => {
    const imagesArray: any = [];
    Details.Images.map((img, index) => {
       imagesArray.push({
          original: img,
          thumbnail: img,
          // originalWidth: "80%",
          // thumbnailWidth: "50%",
        });
    });
    if (Details?.Captions) {
      setCurrentCaption(Details?.Captions[0]);
    }
    setImages(imagesArray);
  }, [Details]);
  const OPTIONS: EmblaOptionsType = { loop: true };
  const SLIDE_COUNT = Details?.Images.length;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  useEffect(() => {}, [showThumbnailsOnFullScreen]);
  const renderItem = (item: any) => {
    return (
      <div className="image-gallery-item">
        <div className="custom-image-container">
          <img src={item?.original} srcSet={item?.srcSet} />
        </div>
      </div>
    );
  };
  return (
    <>
      <style>
        {`
        .image-gallery-icon:hover{
color: unset;
        }
        .image-gallery-slide .image-gallery-description{
          background: none !important;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
          font-weight: 800;
          font-size: 20px;
        }
 .arrowAlign{
  top: 50%;
          }
     
       
@media screen and (max-width: 767px) {
  .image-gallery-slide .image-gallery-description{
    cursor:none;

  font-size: 14px;
  }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    cursor: none;
  }
  .custom-image-container{
    cursor: none;
  }
  .arrowAlign{
    top: 45%;
            }
  .image-gallery-slide-wrapper{
    cursor:none;
    width:100% !important;
  }
  .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-right{
   width: 0px;
   visibility: collapse; 
  }
}

.slide-in-right {
   ${animationClass}
}
@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.image-gallery-bullet{
background:#BBB8AC !important;
padding: 4px !important;
border: none !important;

}
.image-gallery-bullets .image-gallery-bullet.active {
  background: transparent !important;
border: 3px solid black !important;
padding: 2px !important;
}

${
  !showThumbnailsOnFullScreen &&
  `
  .image-gallery-content, .image-gallery-thumbnails-right fullscreen{
height:100%;
display:flex;
align-items:center;
justify-content:center;
  }

  .image-gallery-content.fullscreen{
    text-align: center;
  }
  @media screen and (min-width: 1024px) {
    .image-gallery-slide-wrapper.image-gallery-thumbnails-right {
      width: 85% !important;
    }
  }
    `
}
 
      `}
      </style>
      <div className="pageFadeAnimation py-6 px-2 md:px-6 text-primary-cream select-none cursor-none flex flex-col gap-2">
        <div className="text-heading_md md:text-heading_lg lg:text-heading_xl ">
          {Details?.Title}
        </div>
        {Details?.Images && Details?.Images?.length > 0 && (
          <div className="w-full lg:w-[85%] xl:w-[75%] h-full flex flex-col gap-2 justify-center select-none md:px-0 place-self-center cursor-none">
            <ImageGallery
              items={images}
              renderItem={renderItem}
              showFullscreenButton={true}
              showThumbnails={showThumbnailsOnFullScreen}
              onScreenChange={() => {
                setShowThumbnailsOnFullscreen(!showThumbnailsOnFullScreen);
              }}
              thumbnailPosition="right"
              showPlayButton={false}
              showBullets={true}
              renderLeftNav={(onClick: any, disabled: any) => (
                <button
                  onClick={onClick}
                  disabled={disabled}
                  className="custom-right-nav left-2 absolute arrowAlign z-10"
                >
                  <img src={leftArrowButton} alt="" width={35} height={35} />
                </button>
              )}
              renderRightNav={(onClick: any, disabled: any) => (
                <button
                  onClick={onClick}
                  disabled={disabled}
                  className="custom-right-nav right-2 absolute arrowAlign z-10"
                >
                  <img src={rightArrowButton} alt="" width={35} height={35} />
                </button>
              )}
              onSlide={(currentIndex: any) => {
                if (Details?.Captions) {
                  setAnimationClass(
                    "-webkit-animation: slide-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; animation: slide-in 0.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;"
                  );
                  setCurrentCaption(Details?.Captions[currentIndex]);
                  setTimeout(() => {
                    setAnimationClass("");
                  }, 500);
                }
              }}
            />
            <div className="w-full h-full md:pb-6">
              <div className="w-fit h-full overflow-hidden text-[14px]">
                {currentCaption.split("\n").map((line, index) => (
                  <p key={index} className="">
                    {line}
                    <br />
                  </p>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
