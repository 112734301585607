import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PhotoAndAcademicDetailContent, ProfessionalDetails } from "../../Constant";
import { ProfessionalDetail } from "../common/ProfessionalDetail";
import { PhotoAndAcademicDetail } from "../common/PhotoAndAcademicDetail";
import { Button } from "../common/Button";

export const ProjectDetails = (props:{
  activeTab?:number
}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const { projectName } = useParams();

  const SingleDetail = ProfessionalDetails.find((item) => item?.Tag === projectName);

  const PhotoDetail = PhotoAndAcademicDetailContent.find( (item) => item?.Tag === projectName);
  return (
    <>
      {
        SingleDetail ?
          <ProfessionalDetail Details={SingleDetail} />
          :
          PhotoDetail && <PhotoAndAcademicDetail Details={PhotoDetail} />
      }
      <div className="pageFadeAnimation select-none  text-center text-heading_xs sm:text-heading_sm lg:text-heading_md pt-12 sm:pt-0 pb-16">
        <Button variant="SECONDARY_LINK_BUTTON" className="" children={"Back To Projects"} onClick={() => {navigate(`/projects#projects`, {state: {activeTab: location?.state?.activeTab}})}} />
      </div>
    </>
  );
};
