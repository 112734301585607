export const UnderConstructionPage = () => {
    return (
        <>
            <div className="min-h-screen flex items-center justify-center bg-primary-black text-primary-cream">
                <div className="text-center">
                    <h1 className="text-4xl font-bold mb-4 p-3 sm:p-0">Under Construction</h1>
                    <p className="text-lg mb-8">We're working on something awesome! Check back soon.</p>
                    <div className="animate-bounce p-3 sm:p-0">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-10 w-10 text-primary-cream"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                        />
                    </svg>
                    </div>
                </div>
            </div>
        </>
    )
}