import { Button } from "../common/Button"
import ProfileImage from "../../assets/about/Narek-Headshot-1-1.jpg";
import DemoPDF from "../../assets/about/Resume_Narek Tashdjian_2023.pdf";
import { alertMessage } from "../../Constant";

export const About = () => {
    return (
        <>
            <div className="pageFadeAnimation p-6 sm:pl-6 sm:pr-5 md:p-6 lg:px-36 ">
                <div className="flex flex-col gap-6 sm:gap-12">
                    <div className="select-none inline-block bg-gradient-to-r from-primary-light_cream to-primary-cream bg-clip-text text-transparent text-heading_xs sm:text-heading_md lg:text-heading_lg sm:pr-32 lg:pr-0 xl:pr-52">
                        Collaborating with developers, architects, and interior designers in New York and California, my versatile expertise extends to a wide array of projects, spanning both commercial and residential interiors, lobbies, and retail storefronts.
                    </div>
                    <div className="">
                        <a href={DemoPDF} download="Narek_Tashdjian's_CV" target="_blank">
                            <Button variant="PRIMARY_BUTTON" className="text-xl  sm:!px-10 sm:!py-2 sm:text-heading_xs lg:text-heading_xs lg:!px-12 lg:!py-4" children={"Download CV"} />
                        </a>
                    </div>
                </div>
                <div className="text-primary-cream mt-16 w-full flex flex-col gap-12 lg:gap-16">
                    <div className="select-none">
                        <div className="flex gap-6 items-center text-grey_neutral-light_grey">
                            <div className="text-xl">about me</div>
                            <hr className="flex-grow" />
                        </div>
                        <div className="mt-6">
                            <div className="flex flex-col gap-9 sm:flex-row w-full">
                                <div className="select-none text-lg sm:text-heading_xs sm:w-[65%]">
                                    Throughout my career, I have consistently showcased my unwavering commitment to delivering meticulously designed spaces and experiences. My attention to detail, critical thinking skills, and ability to contribute to the design process from conceptualization through execution have been the cornerstone of my success, ensuring a holistic approach to design.
                                </div>
                                <div className="profile sm:w-[35%]">
                                    <img className="w-full h-full align-middle" src={ProfileImage} alt="No Image Found" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="select-none">
                        <div className="flex gap-6 items-center text-grey_neutral-light_grey">
                            <div className="text-xl">photography</div>
                            <hr className="flex-grow" />
                        </div>
                        <div className="select-none text-lg sm:text-heading_xs pt-6 lg:pl-[122px]">
                            Throughout the years, my passion for photography has remained constant, both on a personal level and in professional endeavors. I capture the stories of life, finding beauty in the everyday moments of human interaction and the lasting impressions of architecture. Across diverse corners of our world, my photography focuses on the simple yet profound connections between people and their surroundings, highlighting the magic found in the ordinary.
                        </div>
                    </div>
                    <div className="select-none">
                        <div className="flex gap-6 items-center text-grey_neutral-light_grey">
                            <div className="text-xl">approach</div>
                            <hr className="flex-grow" />
                        </div>
                        <div className="select-none text-lg sm:text-heading_xs pt-6 lg:pl-[122px]">
                            <div className="p1">
                                By infusing creativity, technical expertise, and a passion for architectural innovation in every project, I am dedicated to crafting spaces that inspire, engage, and exceed expectations. I approach each collaboration with a commitment to open communication, efficient problem-solving, and unwavering attention to detail, ensuring that your vision is realized with precision and excellence.
                            </div>
                            <br />
                            <div className="p2">
                                Whether you're embarking on a new architectural project or seeking a photographer to capture your latest endeavor, let's connect. I look forward to assisting in bringing your vision to life.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="select-none mt-6 sm:mt-10 lg:mt-12 mb-[4.5rem] sm:mb-14 md:mb-20 lg:mb-28 lg:pl-[122px]">
                    <Button variant="SECONDARY_LINK_BUTTON" className="text-heading_xs sm:text-heading_sm md:text-heading_md" children={"Contact me"} onClick={() => window.open("mailto:narek@studio-nt.co", "_blank")} />
                </div>
            </div>
        </>
    )
}