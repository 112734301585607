import { Canvas } from "react-three-fiber";
import { Helmet } from "react-helmet";
import useExternalScripts from "../../hooks/useExternalScripts";
import '../../canvas.css'
import { useEffect } from "react";
export const CanvasPage = () => {
  const orbStyle="orb-w "
  const created = ({ gl }: any) => {
    gl.setClearColor("#141413", 1);
  };

  return (
    <>

      {/* <div className="body fixed w-full h-full -z-10"> */}
        <div className="page-w">
          <main data-taxi="" className="main-w">
            <div data-page="home" data-taxi-view="" className="taxi-w"></div>
          </main>
        </div>
        <div data-orb-wrap="" pointer-none="" className={orbStyle}>
          <div data-orb="" className="orb"></div>
        </div>
      {/* </div> */}
    </>
  );
};
