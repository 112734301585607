import { useEffect } from 'react';

export default function useExternalScripts({ url }:{url:string}){
  useEffect(() => {
    setTimeout(() => {
      var orb = document?.querySelector("[data-orb]");
      if (orb) {
        const head = document?.querySelector("head");
        const script = document?.createElement("script");
    
        script.setAttribute("src", url);
        head?.appendChild(script);
    
        return () => {
          head?.removeChild(script);
        };
      }
    }, 1000);
  }, [url]);
};