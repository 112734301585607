enum ButtonType {
    LARGE = "LARGE",
    MEDIUM = "MEDIUM",
    SMALL = "SMALL",
  }
  
  enum ButtonVariant {
    PRIMARY_BUTTON = "PRIMARY_BUTTON",
    PRIMARY_LINK_BUTTON = "PRIMARY_LINK_BUTTON",
    SECONDARY_LINK_BUTTON = "SECONDARY_LINK_BUTTON",
  }
  
  interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    Type?: keyof typeof ButtonType;
    variant?: keyof typeof ButtonVariant;
    Disable?: boolean;
  }
  
  export const Button = ({
    className,
    Type,
    variant,
    children,
    ...props
  }: React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps) => {
    const styleFunction = () => {
      switch (variant) {
        case "PRIMARY_BUTTON":
          return `bg-primary-green !cursor-none text-heading_xs rounded-full px-8 py-2 md:px-11 py-3 xl:px-16 xl:py-4 text-primary-black hover:bg-primary-light_green disabled:bg-grey_neutral-light_grey disabled:text-grey_neutral-med_grey`;
  
        case "PRIMARY_LINK_BUTTON":
          return "text-primary-cream !cursor-none hover:text-grey_neutral-light_grey disabled:text-grey_neutral-med_grey px-16";
  
        case "SECONDARY_LINK_BUTTON":
          return "text-primary-cream cursor-none hover:text-primary-green disabled:text-grey_neutral-med_grey";
      }
    };
  
    return (
      <>
        <style>
          {`
          .passingLine {
              position: relative;
              padding-bottom: 5px;
            }
            
          .passingLine::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 4px;
              border-radius: 4px;
              background-color: #F1EFE8;
              bottom: 0;
              left: 0;
              transform-origin: left;
              transform: scaleX(1);
              transition: transform .3s ease-in-out;
          }
            
          .passingLine:hover::before {
              left: -5px;
              width: 105%;
              transform-origin: right;
              transform: scaleX(0);
            }
          `}
        </style>
        <button className={`${styleFunction()} ${className} ${variant === "SECONDARY_LINK_BUTTON"? 'passingLine' : '' } cursor-none`} {...props}>
          {children}
        </button>
      </>
    );
  };
  