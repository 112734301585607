import { useState } from "react";
import { HomeImages, alertMessage } from "../../Constant";
import { HomeImageFormat } from "../../types";
import { Button } from "../common/Button";
import { useNavigate } from "react-router-dom";
export const Home = () => {
  const navigate = useNavigate();
  const [cursorScale, setCursorScale] = useState(1);
  const [hoveredIndices, setHoveredIndices] = useState<number[]>(
    new Array(HomeImages.length).fill(0)
  );

  const handleMouseOver = (contentIndex: number, innerIndex: number) => {
    const newHoveredIndices = [...hoveredIndices];
    newHoveredIndices[contentIndex] = innerIndex + 1;
    setHoveredIndices(newHoveredIndices);
    const img = document.getElementById(`${innerIndex}`);
    img?.classList.add("animate-image-in");
  };
  const handleMouseOut = (contentIndex: number, innerIndex: number) => {
    const newHoveredIndices = [...hoveredIndices];
    newHoveredIndices[contentIndex] = 0;
    setHoveredIndices(newHoveredIndices);
    const img = document.getElementById(`${innerIndex}`);
    img?.classList.remove("animate-image-in");
  };
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e: any) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  return (
    <>
      <style>
        {`
          @keyframes move {
            to { transform: translateX(-100%); }
          }
          .animate-move {
            animation: move 10.5s linear infinite;
          }
          
@keyframes fadeImgIn {
  0% { opacity: 0.25 }
  100% { opacity: 1 }
}
.animate-image-in {
  animation: fadeImgIn 0.25s linear;
}
 
@keyframes fadeImgOut {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.animate-image-out {
  animation: fadeImgOut 0.25s linear;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
.fading-image-container {
  position: relative;
  display: inline-block;
  cursor:none;
}
.fading-image-container img {
  transition: opacity 0.5s ease-in-out;
}
.second-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none; 
  cursor:none;
  transition: opacity 0.5s ease-in-out;
}
.fading-image-container:hover .second-image {
  opacity: 1;
  pointer-events: auto; 
}
}
        `}
      </style>
      <div
        className="pageFadeAnimation text-primary-cream overflow-hidden "
        onMouseMove={handleMouseMove}
      >
        <div className="select-none animate-move w-max translate-x-[70%] sm:translate-x-full text-title_sm sm:text-title_md lg:text-title_lg flex items-center justify-center lg:pb-[10%] h-[80vh] md:h-[90vh] lg:h-[100vh] ">
          STUDIO . NT
        </div>
        <div className="select-none flex flex-col items-center gap-52 md:gap-72 mt-32 text-center">
          <div
            className="w-[13ch] text-heading_md sm:text-heading_xl lg:text-title_sm text-wrap"
          
          >
            ARCHITECTURAL DESIGNER
          </div>
          <div className="text-heading_md sm:text-heading_xl lg:text-title_sm">
            FOCUSED ON
          </div>
          <div className="w-[11ch] md:w-[65%] lg:w-[80%] text-heading_md sm:text-heading_xl lg:text-title_sm">
            SPATIAL AND EXPERIENTIAL DESIGN
          </div>
        </div>
        <div className="select-none pl-6 pb-4 pt-32 md:pt-20 lg:pt-48 text-heading_sm sm:text-heading_md lg:text-heading_lg">
          Featured Work
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 justify-center items-center text-center">
          {HomeImages &&
            HomeImages?.map((content: HomeImageFormat, index: number) => (
              <>
                <div
                  id={content.Id.toString()}
                  key={index}
                  className={`items-center flex flex-col h-full p-6 border-b-2 ${
                    content.Id % 2 !== 0 && "lg:border-r-2"
                  } ${[1, 2].includes(content.Id) && "lg:border-t-2"} ${
                    content.Id === 1 && "border-t-2"
                  }`}
                >
                  {content.Image.map((image: string, innerIndex: number) => (
                    <>
                      {innerIndex === 0 && (
                        <button
                          onClick={() =>
                            navigate(
                              `/projects/${content?.Label.toLowerCase().replaceAll(
                                " ",
                                "_"
                              )}`
                            )
                          }
                          className="w-full group relative fading-image-container">
                          <img
                            id={`${Number(innerIndex)}`}
                            className={`select-none w-full aspect-[3/2] h-full align-middle `}
                            src={content.Image[0]}
                            alt="No Image Found"
                          />
                          <img
                            id={`${Number(innerIndex)}`}
                            className={`select-none w-full aspect-[3/2] absolute top-0 z-10 h-full align-middle second-image`}
                            src={content.Image[1]}
                            alt="No Image Found"
                          />
                        </button>
                      )}
                    </>
                  ))}
                  <div className="flex justify-between items-center pt-4 w-full">
                    <div className="text-heading_xs select-none">
                      {content.Label}
                    </div>
                    <div className="select-none text-sm bg-grey_neutral-dark_grey text-primary-green rounded-full p-1.5 md:px-2">
                      {content.Category}
                    </div>
                  </div>
                </div>
              </>
            ))}
        </div>
        <div className="select-none text-center text-heading_xs sm:text-heading_sm lg:text-heading_md pt-8 pb-16">
          <Button
            variant="SECONDARY_LINK_BUTTON"
            children={"View All"}
            onClick={() =>   navigate(`/projects`)}
          />
        </div>
      
      </div>
    </>
  );
};
