import React, { PropsWithChildren, useEffect } from 'react'
import rightArrowButton from '../../assets/CarousalArrowSquareRight.png'
import leftArrowButton from '../../assets/CarousalArrowSquareLeft.png'
type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <button className='w-9 h-9' type="button" {...restProps}>
       <a className='w-9 h-9  text-3xl text-grey_neutral-med_grey'>. </a>
    </button>
  )
}

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props
  return (
    <button
      className="embla__button embla__button--prev z-50"
      type="button"
      {...restProps}
    >
      <svg className="embla__button__svg w-9 h-9 cursor-none" viewBox="0 0 532 532">
      <image href={leftArrowButton} width="100%" height="100%" />

      </svg>
      {children}
    </button>
  )
}

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="embla__button embla__button--next z-50 "
      type="button"
      {...restProps}
    >
      <svg className="embla__button__svg h-9 w-9 right-[12%] cursor-none" viewBox="0 0 532 532">
      <image href={rightArrowButton} width="100%" height="100%" />
      </svg>
      {children}
    </button>
  )
}
