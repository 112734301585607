import { Outlet } from 'react-router-dom';
import { Navbar } from "./common/Navbar"
import { Footer } from './common/Footer';
import { useEffect, useState } from 'react';

export const Layout = () => {
    const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e: any) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>
    )
}