import { Option_Media_Link_Tabs, ImageFormat, HomeImageFormat, ProfessionalDetailsFormat, PhotoAndAcademicFormat } from "./types";
// City Spire
// Images
import CitySpire1 from "./assets/professional/CitySpire/Studio_NT_CitySpire_1.jpeg";
import CitySpire2 from "./assets/professional/CitySpire/Studio_NT_CitySpire_2.jpeg";
import CitySpire3 from "./assets/professional/CitySpire/Studio_NT_CitySpire_3.jpg";
import CitySpire4 from "./assets/professional/CitySpire/Studio_NT_CitySpire_4.jpg";

// Logos
import TishmanCitySpireLogo1 from "./assets/professional/CitySpire/Logos/tishman-speyer-logo 1.png";
import MdeCitySpireLogo2 from "./assets/professional/CitySpire/Logos/MdeAS.png";
import KNLLogo3 from "./assets/professional/CitySpire/Logos/KNL.png";

// Carousal Images
import CarousalCitySpire1 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_5_New.jpg";
import CarousalCitySpire2 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_6_New.jpg";
import CarousalCitySpire3 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_7_New.jpg";
import CarousalCitySpire4 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_8_New.jpg";
import CarouselCitySpire5 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_9_New.jpg";
import CarouselCitySpire6 from "./assets/professional/CitySpire/Carousel/Studio_NT_CitySpire_10_New.jpg";


// Coconut Grove
// Images
import CoconutGrove1 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_1.jpg";
import CoconutGrove2 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_2.jpeg";
import CoconutGrove3 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_3.jpeg";
import CoconutGrove4 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_4.jpeg";
import CoconutGrove5 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_5.jpeg";
import CoconutGrove6 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_6.jpeg";
import CoconutGrove7 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_7.jpeg";
import CoconutGrove8 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_8_New.jpeg";
import CoconutGrove9 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_9_New.jpeg";
import CoconutGrove10 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_10.jpeg";
import CoconutGrove11 from "./assets/professional/Coconut_Grove/Studio_NT_Coconut_Grove_11.jpeg";

// Logos
import StudioCoconutGroveLogo1 from "./assets/professional/Coconut_Grove/Logo/Studio MBM.png";

// Carousal Images
import CarousalCoconutGrove12 from "./assets/professional/Coconut_Grove/Carousel/Studio_NT_Coconut_Grove_12.jpeg";
import CarousalCoconutGrove13 from "./assets/professional/Coconut_Grove/Carousel/Studio_NT_Coconut_Grove_13.jpeg";
import CarousalCoconutGrove14 from "./assets/professional/Coconut_Grove/Carousel/Studio_NT_Coconut_Grove_14.jpeg";
import CarousalCoconutGrove15 from "./assets/professional/Coconut_Grove/Carousel/Studio_NT_Coconut_Grove_15.jpeg";
import CarousalCoconutGrove16 from "./assets/professional/Coconut_Grove/Carousel/Studio_NT_Coconut_Grove_16.jpeg";

// NYU Lobby
// Images
import NYULobby1 from "./assets/professional/NYU_Lobby/Studio_NT_NYU_Lobby_1.jpg";
import NYULobby2 from "./assets/professional/NYU_Lobby/Studio_NT_NYU_Lobby_2.jpg";
import NYULobby3 from "./assets/professional/NYU_Lobby/Studio_NT_NYU_Lobby_3.jpeg";
import NYULobby4 from "./assets/professional/NYU_Lobby/Studio_NT_NYU_Lobby_4.jpeg";

// Logos
import TishmanNYULobbyLogo1 from "./assets/professional/NYU_Lobby/Logos/tishman-speyer-logo 1.png";
import MdeNYULobbyLogo2 from "./assets/professional/NYU_Lobby/Logos/MdeAS.png";
import KNLNYULobbyLogo3 from "./assets/professional/NYU_Lobby/Logos/KNL.png";

// Carousal
import CarousalNYULobby5 from "./assets/professional/NYU_Lobby/Carousel/Studio_NT_NYU_Lobby_5.jpeg";
import CarousalNYULobby6 from "./assets/professional/NYU_Lobby/Carousel/Studio_NT_NYU_Lobby_6.jpeg";
import CarousalNYULobby7 from "./assets/professional/NYU_Lobby/Carousel/Studio_NT_NYU_Lobby_7.jpeg";

// 525 W Monroe
// Images
import Monroe1 from "./assets/professional/525_W_Monroe/Studio_NT_525_W_Monroe_1.jpg";
import Monroe2 from "./assets/professional/525_W_Monroe/Studio_NT_525_W_Monroe_2_New.jpg";
import Monroe3 from "./assets/professional/525_W_Monroe/Studio_NT_525_W_Monroe_3_New.jpg";

// Logos
import TishmanMonroeLogo1 from "./assets/professional/525_W_Monroe/Logos/tishman-speyer-logo 1.png";
import MdeMonroeLogo2 from "./assets/professional/525_W_Monroe/Logos/MdeAS.png";

// Carousal
import CarousalMonroe4 from "./assets/professional/525_W_Monroe/Carousel/Studio_NT_525_W_Monroe_4.jpeg";
import CarousalMonroe5 from "./assets/professional/525_W_Monroe/Carousel/Studio_NT_525_W_Monroe_5.jpeg";
import CarousalMonroe6 from "./assets/professional/525_W_Monroe/Carousel/Studio_NT_525_W_Monroe_6.jpeg";

// TUMO
// Images
import TumoVideo1 from "./assets/professional/TUMO/Studio_NT_TUMO_1.mp4";
import Tumo2 from "./assets/professional/TUMO/Studio_NT_TUMO_2.jpg";
import Tumo3 from "./assets/professional/TUMO/Studio_NT_TUMO_3.jpg";
import Tumo4 from "./assets/professional/TUMO/Studio_NT_TUMO_4.jpg";

// Logos
import TumoLogo from "./assets/professional/TUMO/Logo - NEED HIGHER RES/Tumo.png";

// Carousal for Tumo not provided

// Tiffany & Co.
// Images
import Tiffany1 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_1.jpg";
import Tiffany2 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_2.jpg";
import Tiffany3 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_3.jpg";
import Tiffany4 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_4.jpg";
import Tiffany5 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_5.jpg";
import Tiffany6 from "./assets/photography/Tiffany_Co/Studio_NT_Tiffany_Co_6.jpg";

// Villa
import Villa1 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_1.jpg";
import Villa2 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_2.jpg";
import Villa3 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_3.jpg";
import Villa4 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_4.jpg";
import Villa5 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_5.jpg";
import Villa6 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_6.jpg";
import Villa7 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_7.jpg";
import Villa8 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_8.jpg";
import Villa9 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_9.jpg";
import Villa10 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_10.jpg";
import Villa11 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_11.jpg";
import Villa12 from "./assets/photography/Villa/Studio-NT_Villa-Sayegh_12.jpg";

// Misc.
import Misc1 from "./assets/photography/Misc/Studio_NT_Misc_1.jpg";
import Misc2 from "./assets/photography/Misc/Studio_NT_Misc_2.jpg";
import Misc3 from "./assets/photography/Misc/Studio_NT_Misc_3.jpg";
import Misc4 from "./assets/photography/Misc/Studio_NT_Misc_4.jpg";
import Misc5 from "./assets/photography/Misc/Studio_NT_Misc_5.jpg";
import Misc6 from "./assets/photography/Misc/Studio_NT_Misc_6.jpg";
import Misc7 from "./assets/photography/Misc/Studio_NT_Misc_7.jpg";
import Misc8 from "./assets/photography/Misc/Studio_NT_Misc_8.jpg";
import Misc9 from "./assets/photography/Misc/Studio_NT_Misc_9.jpg";
import Misc10 from "./assets/photography/Misc/Studio_NT_Misc_10.jpg";

// Graduate
import Graduate1 from "./assets/academic/GRAD/Studio-NT_Graduate_1_New.jpg";
import Graduate2 from "./assets/academic/GRAD/Studio-NT_Graduate_2.jpg";
import Graduate3 from "./assets/academic/GRAD/Studio-NT_Graduate_3.jpg";
import Graduate4 from "./assets/academic/GRAD/Studio-NT_Graduate_4.jpg";
import Graduate5 from "./assets/academic/GRAD/Studio-NT_Graduate_5.jpg";
import Graduate6 from "./assets/academic/GRAD/Studio-NT_Graduate_6.jpg";
import Graduate7 from "./assets/academic/GRAD/Studio-NT_Graduate_7.jpg";
import Graduate8 from "./assets/academic/GRAD/Studio-NT_Graduate_8.jpg";
import Graduate9 from "./assets/academic/GRAD/Studio-NT_Graduate_9.jpg";
import Graduate10 from "./assets/academic/GRAD/Studio-NT_Graduate_10.jpg";
import Graduate11 from "./assets/academic/GRAD/Studio-NT_Graduate_11.jpg";
import Graduate12 from "./assets/academic/GRAD/Studio-NT_Graduate_12.jpg";

// Undergraduate
import UnderGraduate1 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_1.jpeg";
import UnderGraduate2 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_2.jpeg";
import UnderGraduate3 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_3.jpeg";
import UnderGraduate4 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_4.jpeg";
import UnderGraduate5 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_5.jpeg";
import UnderGraduate6 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_6.jpeg";
import UnderGraduate7 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_7.jpeg";
import UnderGraduate8 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_8.jpeg";
import UnderGraduate9 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_9.jpeg";
import UnderGraduate10 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_10.jpeg";
import UnderGraduate11 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_11.jpeg";
import UnderGraduate12 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_12.jpeg";
import UnderGraduate13 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_13.jpeg";
import UnderGraduate14 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_14.jpeg";
import UnderGraduate15 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_15.jpeg";
import UnderGraduate16 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_16.jpeg";
import UnderGraduate17 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_17.jpeg";
import UnderGraduate18 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_18.jpg";
import UnderGraduate19 from "./assets/academic/UNDERGRAD/Studio_NT_UnderGraduate_19.jpg";


export const alertMessage = "Work Under Progress";

export enum Categories {
  PROFESSIONAL = "Professional",
  PHOTOGRAPHY = "Photography",
  ACADEMIC = "Academic"
}

export const AppOptions: Option_Media_Link_Tabs[] = [
  {
    Id: 1,
    Label: "Home",
    path: "/",
  },
  {
    Id: 2,
    Label: "Projects",
    path: "/projects",
  },
  {
    Id: 3,
    Label: "About",
    path: "/about",
  },
  {
    Id: 4,
    Label: "Contact",
    path: "/contact",
  },
];

export const SocialMediaLinks: Option_Media_Link_Tabs[] = [
  {
    Id: 1,
    Label: "Linkedin",
    path: "https://www.linkedin.com/in/narek-tashdjian/"
  },
  {
    Id: 2,
    Label: "Archinect",
    path: "https://archinect.com/narektashdjian"
  },
  {
    Id: 3,
    Label: "Instagram",
    path: "https://www.instagram.com/studio.nt.design/"
  },
];

export const ProjectLandingTabs: Option_Media_Link_Tabs[] = [
  {
    Id: 1,
    Label: "Professional",
  },
  {
    Id: 2,
    Label: "Photography",
  },
  {
    Id: 3,
    Label: "Academic",
  },
];

export const ProfessionalContent: ImageFormat[] = [
  {
    Id: 1,
    Label: "CitySpire",
    Image: CitySpire1,
  },
  {
    Id: 2,
    Label: "NYU Lobby",
    Image: NYULobby1,
  },
  {
    Id: 3,
    Label: "Coconut Grove",
    Image: CoconutGrove1,
  },
  {
    Id: 4,
    Label: "525 W Monroe",
    Image: Monroe1,
  },
  {
    Id: 5,
    Label: "TUMO",
    Image: TumoVideo1,
  },
];

export const PhotographyContent: ImageFormat[] = [
  {
    Id: 1,
    Label: `Tiffany & Co.`,
    Image: Tiffany1,
  },
  {
    Id: 2,
    Label: "Villa Sayegh",
    Image: Villa1,
  },
  {
    Id: 3,
    Label: "Misc.",
    Image: Misc1,
  },
];

export const AcademicContent: ImageFormat[] = [
  {
    Id: 1,
    Label: "Undergraduate",
    Image: UnderGraduate1,
  },
  {
    Id: 2,
    Label: "Graduate",
    Image: Graduate1,
  },
];

export const HomeImages: HomeImageFormat[] = [
  {
    Id: 1,
    Label: "CitySpire",
    Category: Categories.PROFESSIONAL,
    Image: [CitySpire1, CitySpire2],
},
  {
    Id: 2,
    Label: "Tiffany & Co.",
    Category: Categories.PHOTOGRAPHY,
    Image: [Tiffany1, Tiffany2],
  },
  {
    Id: 3,
    Label: "Coconut Grove",
    Category: Categories.PROFESSIONAL,
    Image: [CoconutGrove1, CoconutGrove2],
  },
  {
    Id: 4,
    Label: "NYU Lobby",
    Category: Categories.PROFESSIONAL,
    Image: [NYULobby1, NYULobby2],
  },
];

export const ProfessionalDetails: ProfessionalDetailsFormat[] = [
  {
    Id: 1,
    Main: { Image: CitySpire1 },
    Tag: "cityspire",
    Label: "CitySpire",
    Description: `Originally conceived by Helmut Jahn, the lobby of this Midtown Manhattan space underwent an extensive revitalization. In partnership with Raul de Armas, the Founder of MdeAS, and Mike Zaborski, the Architect of Record, I assumed the role of project manager for this transformative renovation. The primary objective was to contemporize the space while focusing on the client's desire for a hospitable atmosphere. \n \n From the initial conceptualization to the development of construction documents, I actively participated in problem-solving and project administration, ensuring a comprehensive set of detailed plans for optimal execution. Successful outcomes were achieved through effective coordination and collaboration with key stakeholders, including Tishman Speyer, building management, IT teams, consultants, and the design team.`,
    Category: Categories.PROFESSIONAL,
    Roles: ["DESIGN", "PROJECT MANAGEMENT", "CONSTRUCTION DOCUMENTATION", "CONSTRUCTION ADMINISTRATION"],
    Logos: [TishmanCitySpireLogo1, MdeCitySpireLogo2, KNLLogo3],
    Images: [{SubImage: [CitySpire2]}, {SubImage:[CitySpire3, CitySpire4]}],
    CarousalImages: [CarousalCitySpire1, CarousalCitySpire2, CarousalCitySpire3, CarousalCitySpire4, CarouselCitySpire5, CarouselCitySpire6]
  },
  {
    Id: 2,
    Main: { Image: NYULobby1 },
    Tag: "nyu_lobby",
    Label: "NYU Lobby",
    Description: "The fully renovated lobby at NYU located at 11 West 42nd Street combines the aesthetics of a historic facade with a distinctly modern interior. As part of the modernization efforts, we introduced a captivating undulating wood slat feature wall, turning the space into a dynamic and welcoming environment for students and staff. Collaborating closely with Mike Zaborski, the Architect of Record for MdeAS, our dedicated focus was on creating a set of drawings tailored to fulfill the requirements of both NYU tenants and Tishman Speyer, the building owners. The challenge was to strike a balance between the objectives of both entities and devise a design that addresses everyone's needs.",
    Category: Categories.PROFESSIONAL,
    Roles: ["ARCHITECTURAL VISUALIZATION", "PROJECT MANAGEMENT", "CONSTRUCTION DOCUMENTATION"],
    Logos: [TishmanNYULobbyLogo1, MdeNYULobbyLogo2, KNLNYULobbyLogo3],
    Images: [{SubImage: [NYULobby2]}, {SubImage: [NYULobby3]}, {SubImage: [NYULobby4]}],
    CarousalImages: [CarousalNYULobby5, CarousalNYULobby6, CarousalNYULobby7]
  },
  {
    Id: 3,
    Main: { Image: CoconutGrove1 },
    Tag: "coconut_grove",
    Category: Categories.PROFESSIONAL,
    Description: "In collaboration with Studio MBM, we embarked on a creative journey at the Coconut Grove residence, entrusted with the task of shaping its interior spaces and outdoor terrace. Our scope included the design of the staircase, kitchen, bar, powder room, master bathroom, and the captivating outdoor terrace. Intrigued by the natural allure of stone, we meticulously arranged each piece, transforming the bathroom, powder room, bar, and kitchen into playful yet provocative mosaics. The staircase became a sculptural composition, seamlessly melding with the existing space, beckoning visitors to ascend through the arched ceiling and up to the terrace. Here, we delineated three distinct zones: a dining + barbeque area, lounge retreat, and an area prime for vibrant entertainment. In this collaborative endeavor, technical precision and creative flair intermingled, resulting in spaces that resonate with elegance and charm.",
    Label: "Coconut Grove",
    Roles: ["DESIGN", "ARCHITECTURAL VISUALIZATION", "CONSTRUCTION SKETCHES"],
    Logos: [StudioCoconutGroveLogo1],
    Images: [{SubImage: [CoconutGrove2]}, {SubImage: [CoconutGrove3]}, {SubImage: [CoconutGrove4, CoconutGrove5]}, {SubImage: [CoconutGrove6]}, {SubImage: [CoconutGrove7]}, {SubImage: [CoconutGrove8, CoconutGrove9]}, {SubImage: [CoconutGrove10]}, {SubImage: [CoconutGrove11]}],
    CarousalImages: [CarousalCoconutGrove12, CarousalCoconutGrove13, CarousalCoconutGrove14, CarousalCoconutGrove15, CarousalCoconutGrove16]
  },
  {
    Id: 4,
    Main: { Image: Monroe1 },
    Tag: "525_w_monroe",
    Label: "525 W Monroe",
    Description: "The design vision for this renovation aimed to cultivate spaces that foster connection, transformation, and the actualization of individual potential. In close collaboration with Mike Zaborski, the Architect of Record for MdeAS, representing Tishman Speyer in Chicago, our focused efforts were dedicated to developing precise production drawings. This collaborative approach extended to working closely with interior designers, ensuring a seamless integration of their vision. I oversaw the drawing production and guided our junior team members through construction documentation. The entire process was defined by a meticulous focus on detail and a streamlined execution.",
    Category: Categories.PROFESSIONAL,
    Logos: [TishmanMonroeLogo1, MdeMonroeLogo2],
    Roles: ["ARCHITECTURAL VISUALIZATION", "PROJECT MANAGEMENT", "CONSTRUCTION DOCUMENTATION"],
    Images: [{SubImage: [Monroe2]}, {SubImage: [Monroe3]}],
    CarousalImages: [CarousalMonroe4, CarousalMonroe5, CarousalMonroe6]
  },
  {
    Id: 5,
    Main: { Video: TumoVideo1 },
    Tag: "tumo",
    Label: "TUMO",
    Category: Categories.PROFESSIONAL,
    Description: "TUMO is an international outreach program aimed at providing free education to young people. I was brought on board by Tumo for a two-month workshop centered on instructing design principles and constructing 3D fabricated furniture tailored for children and teenagers. My role encompassed guiding participants through design fundamentals and adeptly overseeing multiple facets of the project. As the workshop advanced into its second month, we transitioned to the hands-on phase, bringing two unique 3D fabricated chairs to fruition. This collaborative effort, driven by a shared commitment to education and creativity, exemplifies the impactful initiatives fostered through Tumo's partnerships.",
    Roles: ["TEACHING", "DESIGN-BUILD", "PROJECT MANAGEMENT"],
    Logos: [TumoLogo],
    Images: [{SubImage: [Tumo2]}, {SubImage: [Tumo3]}, {SubImage: [Tumo4]}],
    CarousalImages: []
  }
];

export const PhotoAndAcademicDetailContent: PhotoAndAcademicFormat[] = [
  {
    Id: 1,
    Title: "Tiffany & Co. - The Landmark",
    Category: Categories.PHOTOGRAPHY,
    Tag: "tiffany_&_co.",
    Images: [Tiffany1, Tiffany2, Tiffany3, Tiffany4, Tiffany5, Tiffany6],
    Captions: [`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`,`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`,`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`,`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`,`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`,`Architect: Peter Morino \n Lighting Designers: Tillotson Design Associates`]
  },
  {
    Id: 2,
    Title: "Villa Sayegh",
    Category: Categories.PHOTOGRAPHY,
    Tag: "villa_sayegh",
    Images: [Villa1, Villa2, Villa3, Villa4, Villa5, Villa6, Villa7, Villa8, Villa9, Villa10, Villa11, Villa12],
    Captions: [`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi`,`Architect: Selim Koder \n Lighting Designer: Noor Traboulsi` ]
  
  },
  {
    Id: 3,
    Title: "Misc.",
    Category: Categories.PHOTOGRAPHY,
    Tag: "misc.",
    Images: [Misc1, Misc2, Misc3, Misc4, Misc5, Misc6, Misc7, Misc8, Misc9, Misc10],
    Captions: [`Manhattan Skyline`, `Philharmonie de Paris: Jean Nouvel`, `Sequoia National Park`, `Avenue of the Giants`, `Parc de la Villette: Bernard Tschumi`, `Apartment Building in Yerevan: Armenia`, `Armenia Independence Day Parade`, `Home in Gyumri, Armenia`, `Villager in Byurakan, Armenia`, `Taxi Driver in Gyumri, Armenia`]
  },
  {
    Id: 4,
    Title: "Undergraduate",
    Category: Categories.ACADEMIC,
    Tag: "undergraduate",
    Images: [UnderGraduate1, UnderGraduate2, UnderGraduate3, UnderGraduate4, UnderGraduate5, UnderGraduate6, UnderGraduate7, UnderGraduate8, UnderGraduate9, UnderGraduate10, UnderGraduate11, UnderGraduate12, UnderGraduate13, UnderGraduate14, UnderGraduate15, UnderGraduate16, UnderGraduate17, UnderGraduate18, UnderGraduate19],
    Captions: [`Ballona Creek Boat Pavillion \n Professor: Gerard Smulevich`, `Ballona Creek Boat Pavillion \n Professor: Gerard Smulevich`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Thesis: Space for Spectacle \n Professors: Ewan Branda + James Michael Tate`, `Seed Bank: Design Build \n Professors: Jeanine Centuori + Elizabeth Timme, Photograph: Gerard Smulevich`, `Seed Bank: Design Build \n Professors: Jeanine Centuori + Elizabeth Timme, Photograph: Gerard Smulevich`, `Woodbury Campus Extension \n Professor: Daniel Rabin`, `Module Design Concept \n Professor: Molly Hunker`, `Module Design Concept \n Professor: Molly Hunker`]
  },
  {
    Id: 5,
    Title: "Graduate",
    Category: Categories.ACADEMIC,
    Tag: "graduate",
    Images: [Graduate1, Graduate2, Graduate3, Graduate4, Graduate5, Graduate6, Graduate7, Graduate8, Graduate9, Graduate10, Graduate11, Graduate12],
    Captions: [`Webb \n Professors: Mark Bearak + Brigette Borders`, `Webb \n Professors: Mark Bearak + Brigette Borders`, `Paradoxical Efficiencies Professor: Marc Tsurumaki`, `Paradoxical Efficiencies \n Professor: Marc Tsurumaki`, `Alternating Realities \n Professor: Mario Gooden`, `Office Park \n Professors: Dan Wood + Maurizio Bianchi Mattioli`, `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman`, `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman`, `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman` , `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman`, `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman`, `Hubby - Transformable Design Methods \n Professor: Chuck Hoberman` ]
  }
]