import { useEffect, useState } from "react";
import Hamburger from "../../assets/HamburgerIcon.svg";
import Close from "../../assets/CloseIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { AppOptions } from "../../Constant";
import { Option_Media_Link_Tabs } from "../../types";
import useExternalScripts from "../../hooks/useExternalScripts";

export const Navbar = () => {
  const location = useLocation();
  const {pathname} = useLocation()
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleOrb = (pathName:any) =>{
    if(document.getElementsByClassName("orb-w")[0]) {
      const OrbTochange = document.getElementsByClassName("orb-w")[0];
      if(pathName != "/" || pathName != '/projects') {
        OrbTochange.classList.add("!hidden");
       }else if(pathName === "/" || pathName === '/projects') {
        OrbTochange.classList.remove("!hidden");
        OrbTochange.classList.add("!flex");
      }else{
        return;
      }
    }
  }
  
  useEffect(()=>{
    handleOrb(location.pathname)
      },[location.pathname]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  };

  return (
    <>
     <div className={`${isMenuOpen && "h-screen"} backdrop-blur-lg select-none sticky top-0 w-full overflow-hidden z-[100] p-6`}>
        <div className=" w-full h-fit flex text-primary-cream justify-between items-center">
          <div className=" text-heading_xs" onClick={() => {navigate("/"); setIsMenuOpen(false); handleOrb("/")}}>STUDIO . NT</div>
          {/* Hamburger menu button */}
          <div className="md:hidden " onClick={toggleMenu}>
            <img className="h-full w-full align-middle" src={isMenuOpen ? Close : Hamburger} alt="No Image Found" />
          </div>
          {/* Menu items for larger screens */}
          <div className="hidden md:flex justify-around text-md gap-10 md:gap-28">
            {
              AppOptions && AppOptions.map( (item: Option_Media_Link_Tabs, index: number) => {
                if(["Projects", "About", "Contact"].includes(item.Label)) {
                  return (
                    <>
                    {
                      "Contact" === item?.Label ?
                      <div key={index} className={` ${item.path === pathname && "text-primary-green"}`} onClick={() => { window.open("mailto:narek@studio-nt.co", "_blank"); handleOrb(item.path); }}>{item.Label}</div>
                      :
                      <div key={index} className={` ${item.path === pathname && "text-primary-green"}`} onClick={() => { navigate(`${item.path}`); handleOrb(item.path); }}>{item.Label}</div>
                    }
                    </>
                  )
                }
              })
            }
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
         <div className="text-md md:hidden text-primary-cream p-2 flex flex-col gap-10">
            <div className="hr mt-4">
              <hr />
            </div>
            {
              AppOptions && AppOptions.map( (item: Option_Media_Link_Tabs, index: number) => {
                if(["Projects", "About", "Contact"].includes(item.Label)) {
                  return (
                    <>
                    {
                      "Contact" === item?.Label ?
                      <div key={index} className={` ${item.path === pathname && "text-primary-green"}`} onClick={() => { window.open("mailto:narek@studio-nt.co", "_blank"); toggleMenu(); handleOrb(item.path); }}>{item.Label}</div>
                      :
                      <div key={index} className={` ${item.path === pathname && "text-primary-green"}`} onClick={() => { navigate(`${item.path}`); toggleMenu(); handleOrb(item.path); }}>{item.Label}</div>
                    }
                    </>
                  )
                }
              })
            }
          </div>
        )}
      </div>

    </>
  )
}