import React, { useCallback, useEffect, useRef, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { flushSync } from "react-dom";
import Close from "../../assets/CloseIcon.svg";
import ImageGallery from "react-image-gallery";

import rightArrowButton from "../../assets/CarousalArrowSquareRight.png";
import leftArrowButton from "../../assets/CarousalArrowSquareLeft.png";
import { EmblaCarouselType, EmblaOptionsType } from "embla-carousel";
import {
  DotButton,
  PrevButton,
  NextButton,
} from "./EmblaCarouselArrowsDotsButtons";

const TWEEN_FACTOR = 1;
const TWEEN_FACTOR_OPACITY = 4.2;

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max);

type PropType = {
  slides: number[];
  options?: EmblaOptionsType;
  images: string[];
};
const imageByIndex = (index: number, images: string[]): string =>
  images[index % images.length];
const EmblaCarousel: React.FC<PropType> = (props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [changingIndex, setChangingIndex] = useState<number>(0);

  const handleSlide = (index: number) => {
    setCurrentIndex(index);
  };
  const galleryRef = useRef<ImageGallery>(null);
  const goToSlide = (index: number) => {
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(index);
    } else {
    }
  };

  const getCurrentIndex = (): number => {
    if (emblaApi) {
      return emblaApi.selectedScrollSnap();
    }
    return -1; // Or any default value you want to return if emblaApi is not available
  };
  const getEmblaCarouselIndex = () => {
    const currentIndex = getCurrentIndex();
    setCurrentIndex(currentIndex)
    goToSlide(currentIndex);
  };
  const setCarouselIndex = (index: number) => {
    if (emblaApi) {
      emblaApi.scrollTo(index);
    }
  };

  const [images, setImages] = useState<any>([]);
  const [isImageGalleryNotNavigated, setIsImageGalleryNotNavigated] = useState(false);
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [tweenValues, setTweenValues] = useState<number[]>([]);
  const [tweenValuesOpacity, setTweenValuesOpacity] = useState<number[]>([]);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [imageToFullscreen, setImageToFullscreen] = useState<string>();
  const [isFullscreen, setisFullscreen] = useState<boolean>(false);

  const toggleFullscreen = (imgSrc: string) => {
    setisFullscreen(true);
    // setImageToFullscreen(imgSrc);
    setIsImageGalleryNotNavigated(true)
    getEmblaCarouselIndex();
  };
  const closeFullScreen = () => {
    setisFullscreen(false);
  };
  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  const onScroll = useCallback(() => {
    if (!emblaApi) return;

    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const opacity: number[] = [];
    const styles = emblaApi.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;
      if (engine.options.loop) {
        engine.slideLooper.loopPoints.forEach((loopItem) => {
          const target = loopItem.target();
          if (index === loopItem.index && target !== 0) {
            const sign = Math.sign(target);
            if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
            if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
          }
        });
      }
      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      const tweenValueOpacity =
        1 - Math.abs(diffToTarget * TWEEN_FACTOR_OPACITY);
      opacity.push(numberWithinRange(tweenValueOpacity, 0.5, 1));
      return numberWithinRange(tweenValue, 0.6, 1);
    });
    setTweenValues(styles);
    setTweenValuesOpacity(opacity);
  }, [emblaApi, setTweenValues]);

  useEffect(() => {
    if (!emblaApi) return;

    onScroll();
    emblaApi.on("scroll", () => {
      flushSync(() => onScroll());
    });
    emblaApi.on("reInit", onScroll);
  }, [emblaApi, onScroll]);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);
  useEffect(() => {
    const imagesArray: any = [];
    props.images.map((img) => {
      imagesArray.push({
        original: img,
        thumbnail: img,
      });
    });
    setImages(imagesArray);
  }, []);

  useEffect(() => {
    if (galleryRef.current) {
      setCurrentIndex(currentIndex)
      galleryRef.current.slideToIndex(currentIndex);

    }
  }, [galleryRef.current]); 

  return (
    <>
      <style>
        {`
       .arrowAlign{
        top: 50%;
                }
        @media screen and (max-width: 767px) {
          .arrowAlign{
            top: 45%;
                    }
                  }
                  
.image-gallery-bullet{
background:#BBB8AC !important;
padding: 4px !important;
border: none !important;

}
.image-gallery-bullets .image-gallery-bullet.active {
  background: transparent !important;
border: 3px solid black !important;
padding: 2px !important;
}
 
    `}
      </style>
      <div className="relative w-full h-full !cursor-none">
        <div className="embla w-full h-full relative flex justify-center overflow-hidden">
          <div
            className="embla__viewport w-full md:w-[75%] h-4/5 lg:w-[67%] lg:h-1/2 z-50"
            ref={emblaRef}
          >
            <div className="embla__container flex xl:items-center">
              {slides.map((index) => (
                <div
                  className="embla__slide"
                  key={index}
                  style={{
                    ...(tweenValuesOpacity.length && {
                      opacity: tweenValuesOpacity[index],
                      transform: `scale(${tweenValues[index]})`,
                    }),
                  }}
                >
                  <div className="embla__scale">
                    <button
                      onClick={() => {
                        toggleFullscreen(props.images[index]);
                      }}
                    >
                      <img
                        className="embla__slide__img cursor-none rounded-lg shadow-[0_4px_4px_0_#BBB8AC] xl:min-h-[600px]"
                        src={imageByIndex(index, props.images)}
                        alt="Your alt text"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="embla__buttons  absolute w-full h-fit flex justify-between top-[45%] lg:top-1/2 px-[12%]">
            <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
            <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
          </div>
        </div>
        {/* <div className="embla__dots absolute w-full h-fit flex justify-center items-center gap-5 -bottom-[10%] sm:-bottom-[12%] md:-bottom-[8%] xl:-bottom-[5%]">
    {scrollSnaps.map((_, index) => (
        <>
       <div  key={index}
         className={'embla__dot'.concat(
           index === selectedIndex ? 'embla__dot--selected w-1 h-1 rounded-full flex bg-primary-cream !outline !outline-black !outline-[6px] mx-1' : ' w-2 h-2 rounded-full text-3xl text-grey_neutral-med_grey bg-grey_neutral-light_grey'
         )}></div> 
        </>
    ))}
  </div> */}
      </div>
      {isFullscreen && (
        <>
          <div className="fixed w-[100vw] h-[100vh] flex items-center justify-center bg-black/80 z-[1000] top-0 p-2 lg:p-10">
            {/* <img src={imageToFullscreen} className='apsect-[3/2] max-w-full max-h-[50%] lg:max-h-full'/>
             */}
            <ImageGallery
              ref={galleryRef}
              items={images}
              showThumbnails={false}
              thumbnailPosition="bottom"
              showPlayButton={false}
              showFullscreenButton={false}
              showBullets={true}
              renderLeftNav={(onClick: any, disabled: any) => (
                <button
                onClick={()=>{onClick();setIsImageGalleryNotNavigated(false)}}
                  disabled={disabled}
                  className="custom-right-nav left-2 absolute arrowAlign z-10"
                >
                  <img src={leftArrowButton} alt="" width={35} height={35} />
                </button>
              )}
              renderRightNav={(onClick: any, disabled: any) => (
                <button
                  onClick={()=>{onClick();setIsImageGalleryNotNavigated(false)}}
                  disabled={disabled}
                  className="custom-right-nav right-2 absolute arrowAlign z-10"
                >
                  <img src={rightArrowButton} alt="" width={35} height={35} />
                </button>
              )}
              onSlide={(thisIndex: any) => {
                if(isImageGalleryNotNavigated){
                  setCurrentIndex(currentIndex);
                }else{
                  setCurrentIndex(thisIndex);
                }
              }}
            />
            <button
              className="w-5 h-5 top-10 right-10 absolute"
              onClick={() => {
                closeFullScreen();
                // set index here
                setCarouselIndex(currentIndex);
              }}
            >
              <img src={Close} />
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default EmblaCarousel;
