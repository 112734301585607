import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  useEffect(() => {
    const handleUnload = () => {
      window.scrollTo(0, 0);
    };
    window.addEventListener('unload', handleUnload);
    return () => {
      window.addEventListener('unload', handleUnload);
    };
  }, []); 
  return null;
}