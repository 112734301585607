import { useLocation, useNavigate } from "react-router-dom";
import { AppOptions, SocialMediaLinks, alertMessage } from "../../Constant";
import { ReactComponent as FooterDesign } from "../../assets/FooterDesign.svg";
import TopRightArrow from "../../assets/TopRightArrow.svg";
import { Option_Media_Link_Tabs } from "../../types";
import { Button } from "./Button";
import { useEffect } from "react";

export const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleOrb = (pathName:any) =>{
        if(document.getElementsByClassName("orb-w")[0]) {
            const OrbTochange = document.getElementsByClassName("orb-w")[0]
            if(pathName != "/" && pathName != '/projects') {
                OrbTochange.classList.add("!hidden");
            } else if(pathName === "/" || pathName === '/projects'){
                OrbTochange.classList.remove("!hidden");
                OrbTochange.classList.add("!flex");
             
            } else{
                return;
            }
        }
    }

    useEffect( () => {
        handleOrb(location.pathname)
    },[location.pathname]);

    return (
        <>
            <div className="main relative">
                <div className="absolute w-full top-10 bottom-1/2 p-5 sm:p-6">
                    <div className="flex items-start flex-col justify-between sm:flex-row sm:items-center">
                        <div className="select-none text-heading_md md:text-heading_lg lg:text-heading_xl text-primary-cream">Let's work together</div>
                        <div className="mt-6 sm:mt-0">
                            <Button type="button" variant={"PRIMARY_BUTTON"} className="select-none" onClick={() => window.open("mailto:narek@studio-nt.co", "_blank")}>
                                Contact
                            </Button>
                        </div>
                    </div>
                    <div className="flex gap-28 sm:justify-normal sm:gap-11 p-1 text-primary-cream mt-12 sm:mt-8">
                        <div className="select-none">
                        {
                            AppOptions && AppOptions?.map( (option: Option_Media_Link_Tabs, index: number) => (
                                    <div key={index} className="mb-4 text-md" onClick={() => { handleOrb(option.path); navigate(`${option.Label === "Contact" ? "/" : option.path}`) }}>{option.Label === "Contact" ? "" : option.Label}</div>
                            ))
                        }
                        </div>
                        <div className="link">
                            {
                                SocialMediaLinks.map( (link: Option_Media_Link_Tabs, index: number) => (
                                    <Button key={index} type="button" className="select-none flex gap-2 items-center group text-md mb-4 !px-0" onClick={() => window.open(`${link?.path}`, `__blank`)} variant={"PRIMARY_LINK_BUTTON"}>
                                        {link.Label}
                                        <img className="h-full w-full align-middle group-hover:rotate-45" src={TopRightArrow} alt="No Image Found" />
                                    </Button>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="bg-grey_neutral-dark_grey flex items-end justify-center">
                    <div className="mt-52 pt-16 pr-5 pl-5 md:pt-0 md:pr-0 md:pl-0 sm:mt-14">
                        <FooterDesign className="select-none h-full w-full align-middle" />
                    </div>
                </div>
            </div>
        </>
    )
}